import '../hero.scss';
import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Breadcrumb from '@components/atoms/Breadcrumb';
import HeroTitle from '@components/atoms/HeroTitle';
import AnchorNav from '@components/molecules/AnchorNav';

const HeroJob = ({
	modifiers,
	className,
	breadcrumb,
	title,
	location,
	anchors,
	...otherProps
}) => {
	const baseClass = 'hero--job';
	const defaultClass = 'hero';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div
			className={rootClass}
			{...otherProps}
			data-hero
			data-animation-page
		>
			<div className="hero__top">
				<div className="hero__breadcrumbs">
					<Breadcrumb {...breadcrumb} />
				</div>
			</div>
			<div className="hero__inner">
				<p className="uptitle">{location}</p>
				<HeroTitle title={title} />
				<AnchorNav anchors={anchors} />
				<span className="hero__background"></span>
			</div>
		</div>
	);
};

HeroJob.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	breadcrumb: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired,
	anchors: PropTypes.array.isRequired,
};

export default HeroJob;
