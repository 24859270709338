import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ButtonAnchor from '@components/atoms/buttons/ButtonAnchor';

const AnchorNav = ({ modifiers, className, anchors, ...otherProps }) => {
	const baseClass = 'anchor-nav';

	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<nav className={rootClass} aria-label="Page Anchors" {...otherProps}>
			{anchors.map((anchor, index) => {
				return (
					<ButtonAnchor
						key={`anchor-${index}`}
						href={anchor.href}
						text={anchor.title}
					/>
				);
			})}
		</nav>
	);
};

AnchorNav.defaultProps = {};

AnchorNav.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	anchors: PropTypes.array.isRequired,
};

export default AnchorNav;
